import React from 'react';
import './styles.css';
import imagen from './assets/banner-img.png'; // Ruta relativa a la imagen
import objetivosimg from './assets/objetivos-tfm.png'; // Ruta relativa a la imagen
import justimg from './assets/just-tfm.png'; // Ruta relativa a la imagen
import metodimg from './assets/metod-tfm.png'; // Ruta relativa a la imagen
import {Footer} from './components/Footer'
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      target: null // Inicialmente establecido como null
    };
    this.handleClick = this.handleClick.bind(this);
    this.scrollToTarget = this.scrollToTarget.bind(this);
  }

  handleClick(e) {
    this.setState({
      target: e.target.value
    }, () => {
      this.scrollToTarget();
    });
  }

  scrollToTarget() {
    const targetElement = document.getElementById(this.state.target);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  render() {
    return (
      <>
      {/* <Navbar sticky='top' bg="light" data-bs-theme="light">
        <Container>
          <Navbar.Brand href="#home">Navbar</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link>Home</Nav.Link>
            <Nav.Link value='objetivos'>Features</Nav.Link>
            <Nav.Link href="">Pricing</Nav.Link>
          </Nav>
        </Container>
      </Navbar> */}

      <div className="app-container">
<header className="banner banner-enter">
  <div className="banner-content">
    <div className="banner-text">
      <h1>
        <span>Competencias Digitales</span> en estudiantes de Pedagogía del Sistema Universidad Abierta y Educación a Distancia de la Universidad Nacional Autónoma de México
      </h1>
      <p>Trabajo Final de Máster - MEEATD</p>
      <button onClick={this.handleClick} value="objetivos">Ver proyecto</button>
    </div>
    <div className="banner-image">
      <img src={imagen} alt="Descripción de la imagen" />
    </div>
  </div>
</header>
        <main>
          {/* Renderiza los componentes solo si target no es null */}
          {this.state.target !== null && (
            <>
              <div id="objetivos" className={this.state.target === "objetivos" ? "fade-enter" : "fade-exit"}>
                {this.state.target === "objetivos" && <Objetivos />}
              </div>
              <div id="preguntas" className={this.state.target === "preguntas" ? "fade-enter" : "fade-exit"}>
                {this.state.target === "preguntas" && <Preguntas />}
              </div>
              <div id="metodologia" className={this.state.target === "metodologia" ? "fade-enter" : "fade-exit"}>
                {this.state.target === "metodologia" && <Metodologia />}
              </div>
              <div id="impacto" className={this.state.target === "impacto" ? "fade-enter" : "fade-exit"}>
                {this.state.target === "impacto" && <ImpactoSocial />}
              </div>
            </>
          )}
        </main>
      </div>
      </>
    );
  }
}

class Objetivos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      target: null // Inicialmente establecido como null
    };
    this.handleClick = this.handleClick.bind(this);
    this.scrollToTarget = this.scrollToTarget.bind(this);
  }

  handleClick(e) {
    this.setState({
      target: e.target.value
    }, () => {
      this.scrollToTarget();
    });
  }

  scrollToTarget() {
    const targetElement = document.getElementById(this.state.target);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  render() {
    return (
      <>
      <div className="section-content">
        <div className="section-image">
          <img src={justimg} alt="Descripción de la imagen" />
        </div>
        <div className="section-text">
          <h2><strong>Justificación</strong></h2>
          <p>
            En un contexto de expansión y diversificación, se hace evidente la importancia de analizar las competencias digitales de los estudiantes del Sistema Universidad Abierta y Educación a Distancia (SUAyED) de la Universidad Nacional Autónoma de México (UNAM). La integración de la tecnología en la educación superior, especialmente en modalidades no presenciales, plantea nuevos desafíos y oportunidades (Bates, 2015). Por lo tanto, es crucial explorar cómo las competencias digitales se entrelazan con las diferentes modalidades educativas, con el objetivo de mejorar la calidad y eficacia de la enseñanza.
          </p>
          <button onClick={this.handleClick} value="impacto">Ver los objetivos</button>
        </div>
      </div>
              <main>
              {/* Renderiza los componentes solo si target no es null */}
              {this.state.target !== null && (
                <>
                  <div id="impacto" className={this.state.target === "impacto" ? "fade-enter" : "fade-exit"}>
                    {this.state.target === "impacto" && <ImpactoSocial />}
                  </div>
                </>
              )}
            </main>
            </>
    );
  }
}

class ImpactoSocial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      target: null // Inicialmente establecido como null
    };
    this.handleClick = this.handleClick.bind(this);
    this.scrollToTarget = this.scrollToTarget.bind(this);
  }

  handleClick(e) {
    this.setState({
      target: e.target.value
    }, () => {
      this.scrollToTarget();
    });
  }

  scrollToTarget() {
    const targetElement = document.getElementById(this.state.target);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  render() {
    return (
      <>
      <div className="section-content-izq">
        <div className="section-image">
          <img src={objetivosimg} alt="Descripción de la imagen" />
        </div>
        <div className="section-text">
          <h2><strong>Objetivos</strong></h2>
          <p>
          Con esto planteado, el objetivo general es describir las CD del alumnado de la Licenciatura en
Pedagogía del SUAyED de la UNAM, campus Ciudad Universitaria (CU). Para ello, se partirá desde
un enfoque metodológico cuantitativo, ya que la recolección de datos se basará en la medición de
las competencias a través de un instrumento estandarizado.
<br></br><br></br>
Con el propósito de alcanzar este objetivo general, se plantean los siguientes objetivos
específicos:</p>
<ul>
  <li>Evaluar niveles de competencia digital.</li>
    <li>Identificar perfiles en función de sus competencias digitales.</li>
    <li>Detectar y analizar posibles brechas digitales dentro del grupo de estudiantes.
</li>
</ul>
<p>
El alcance de este TFM es descriptivo, pues se buscó recolectar información para especificar las
características de perfiles de personas, con la intención de reflejar con mayor detalle las
dimensiones de dicho grupo y así indagar con profundidad sobre las competencias digitales.
</p>
          <button onClick={this.handleClick} value="metodologia">Ver la metodología</button>
        </div>
      </div>
      <main>
              {/* Renderiza los componentes solo si target no es null */}
              {this.state.target !== null && (
                <>
              <div id="metodologia" className={this.state.target === "metodologia" ? "fade-enter" : "fade-exit"}>
                {this.state.target === "metodologia" && <Metodologia />}
              </div>
                </>
              )}
            </main>
            </>
    );
  }
}

class Metodologia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      target: null // Inicialmente establecido como null
    };
    this.handleClick = this.handleClick.bind(this);
    this.scrollToTarget = this.scrollToTarget.bind(this);
  }

  handleClick(e) {
    this.setState({
      target: e.target.value
    }, () => {
      this.scrollToTarget();
    });
  }

  scrollToTarget() {
    const targetElement = document.getElementById(this.state.target);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  render() {
    return (
      <>
      <div className="section-content-last">
        <div className="section-image">
          <img src={metodimg} alt="Descripción de la imagen" />
        </div>
        <div className="section-text">
          <h2><strong>Metodologia</strong></h2>
          <p>
          Para la recolección de la información se adaptará el cuestionario “Competencias básicas digitales 2.0 de los
estudiantes universitarios” COBADI (Marca registrada: 2970648), elaborado por investigadores
del grupo de investigación EDUINNOVAGOGÍA.
<br></br><br></br>
Este cuestionario busca
medir las competencias digitales de estudiantes de nivel universitario a través de 23 ítems
delimitados en tres dimensiones:</p>
<ul>
  <li><strong>Dimensión 1:</strong> “Competencias de uso de las Tecnologías de Información y Comunicación
(TIC) para la búsqueda y tratamiento de la información”</li>
    <li><strong>Dimensión 2:</strong> “Competencias interpersonales en el uso de las TIC en el contexto
universitario”.</li>
    <li>
     <strong>Dimensión 3: </strong>“Herramientas virtuales y de comunicación social de la Universidad”.
</li>
</ul>
<p>
COBADI es un instrumento generado por investigadores e investigadoras de España. Para su
implementación en la presente investigación se tuvo que hacer un análisis gramatical, lingüístico
y semántico con la intención de adaptar el lenguaje a la realidad de la población, es decir, los
estudiantes de la Licenciatura en Pedagogía del SUAyED de la UNAM, en Ciudad Universitaria.
</p>
          <button onClick={this.handleClick} value="preguntas">Me interesa saber más</button>
        </div>
      </div>
      <main>
              {/* Renderiza los componentes solo si target no es null */}
              {this.state.target !== null && (
                <>
              <div id="preguntas" className={this.state.target === "preguntas" ? "fade-enter" : "fade-exit"}>
                {this.state.target === "preguntas" && <Preguntas />}
              </div>
                </>
              )}
            </main>
            </>
    );
  }
}

class Preguntas extends React.Component {
  render() {
    return (
      <>
            <br></br>
            <br></br>
            <br></br>

      <div>
        <h2>¿Te interesa saber más?</h2>
        <p>Te invito a seguir mis publicaciones en LinkedIn.</p>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <Footer/>
      </>
    );
  }
}


export default App;
